@media (max-width: 640px) {
  html {
    font-size: 50%;
  }
}
@media (min-width: 640px) {
  html {
    font-size: 70%;
  }
}
@media (min-width: 1100px) {
  html {
    font-size: 80%;
  }
}
@media (min-width: 1400px) {
  html {
    font-size: 100%;
  }
}

.multi-select span.item-renderer {
  display: flex;
  align-items: center;
}

input,
.dropdown-heading {
  height: 2.2rem !important;
}

.dropdown-heading-value {
  margin-top: -1%;
}

.dropdown-heading-value > span {
  line-height: 2.2rem !important;
}
